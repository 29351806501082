<template>
  <div class="bg-white h-full">
    <div @click="close" class="w-full flex items-center justify-end space-x-3 pt-10 pr-10 cursor-pointer">
      <div class="uppercase body-l-500">Close</div>
      <CloseIcon />
    </div>
    <div v-if="loading" class="max-w-md mx-auto flex items-center justify-center min-h-screen">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="grid grid-cols-1" :class="{'lg:grid-cols-2' : showWhatHappensNext}">      
      <!-- Left-col -->
      <div class="w-full max-w-lg mx-auto py-20 px-6 xl:px-0">
        <transition
          enter-active-class="transition ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          mode="out-in"
        >
          <router-view></router-view>
        </transition>
      </div>
      <!-- Right col -->
      <div v-if="showWhatHappensNext"
        class="p-10 bg-black flex flex-col items-center"
      >
        <div class="relative rounded-full w-48 h-48 mx-auto mt-16 mb-8 overflow-hidden">
          <img
            :src="guest.imageUrl"
            class="w-full h-full object-cover"
            :alt="guest.name"
          />
        </div>
        <h2 class="font-bold text-3xl text-white text-center mb-10">
          What happens next?
        </h2>
        <div>
          <NextStepsAdHocBooking :user="user" :guest="guest" />
        </div>
      </div>
    </div>
    <ReconfirmLoginModal />
    <CloseBookingModal ref="closeBookingModal" />
  </div>
</template>

<script>
import NextStepsAdHocBooking from "@/components/Book/guest/NextStepsAdHocBooking"
import ReconfirmLoginModal from '@/components/auth/ReconfirmLoginModal';
import CloseBookingModal from '@/components/bookings/individual/activity/CloseBookingModal';
import CloseIcon from '@/components/svgs/CloseIcon';
import { chain } from 'lodash'

export default {
  name: "GuestBook",

  components: {
    NextStepsAdHocBooking,
    ReconfirmLoginModal,
    CloseBookingModal,
    CloseIcon,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    guest() {
      return this.$store.getters['guest/getGuest']
    },

    user() {
      return this.$store.getters['auth/getUser']
    },

    queryCode() {
      return this.$route.query.code
    },

    showWhatHappensNext() {
      return this.$route.name == 'GuestBookingPayment'
    },

    bookingOptions() {
      return this.$store.getters['guestBooking/bookingOptions']
    },

    guestOptionPrices() {
      return chain(this.bookingOptions)
        .filter(option => option.slug != 'pitch')
        .map(option => {
          return {
            ...option,
            prices: this.guest.option_prices.filter(price => price.booking_option_id === option.id)
          }
        })
        .filter(option => option.prices.length)
        .sortBy(option => option.id)
        .value()
    },

    isPitch() {
      return this.$store.getters['guestBooking/isPitch'];
    }
  },

  methods: {
    close() {
        this.$refs.closeBookingModal.toggle();
      }
    },

  async created() {
    this.$store.commit('guestBooking/clearBooking')

    await this.$store.dispatch('guestBooking/fetchBookingOptions')

    if (this.$route.params.is_pitch) {
      this.$store.commit('guestBooking/setIsPitch', true);
    }

    let { data: guest } = await this.$store.dispatch('guest/fetchGuest', {
      id: this.$route.params.guestId,
      includes: 'author,pitch_settings',
    })

    if (this.user.id == guest.user_id) {
      return this.$router.push(`/guests/${this.guest.id}`)
    }

    if (guest.author && guest.author.isPaused) {
      this.$router.push({name: 'NotFound'})
    }

    const isPitchEnabled = this.guest.pitch_settings.is_enabled;
    if (this.isPitch && !isPitchEnabled) {
      return this.$router.push(`/guests/${this.guest.id}`);
    }

    if (this.$route.params.bookingOptionId) {
      let option = this.guestOptionPrices.find(option => option.id == this.$route.params.bookingOptionId)

      this.$store.commit('guestBooking/setSelectedBookingOption', {
        ...option,
        price: option.prices[0],
      })
    }

    if (this.guest.option_prices.length && !this.isPitch) {
      this.$store.commit('guestBooking/enableStep', 'options')
    }

    if (this.user.shows.length === 1) {
      this.$store.commit('guestBooking/setShow', this.user.shows[0])
      this.$store.commit('guestBooking/disableStep', 'profile')
    }

    const { data: preferences } =  await this.$store.dispatch('guest/fetchBookingFlowPreferences', this.guest.id);

    if ((this.guest.author && this.guest.author.isPro) || this.isPitch) {
      if ((!this.isPitch && preferences.data.questions) || (this.isPitch && this.guest.pitch_settings.questions)) {
        const { data: questions } =  this.isPitch
          ? await this.$store.dispatch('guest/fetchPitchQuestions', this.guest.id)
          : await this.$store.dispatch('guest/fetchBookingQuestions', this.guest.id);

        if (questions.data.length) {
          this.$store.commit('guestBooking/setQuestions', questions.data)
          this.$store.commit('guestBooking/enableStep', 'questions')
        }
      }

      if (preferences.data.upsells) {
        const { data: upsells } =  await this.$store.dispatch('guest/fetchUpsellPackages', this.guest.id)

        if (upsells.data.length) {
          this.$store.commit('guestBooking/setAvailableUpsellPackages', upsells.data)
          this.$store.commit('guestBooking/enableStep', 'upsells')
        }
      }
    }

    if (this.queryCode && !this.isPitch) {
      this.$store.dispatch('guestBooking/fetchCode', {
        id: this.$route.params.guestId,
        code: this.queryCode
      })
    }

    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('guestBooking/clearBooking');
  },
};
</script>
<style scoped>
.body-l-500{
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
</style>
